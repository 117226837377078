import React, { useEffect } from "react"
import { Alert, Button, Modal, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { pathList } from "../../routes/pathList"
import shareService from "../../services/ShareService"
import { Share } from "../../services/ShareService/types"
import { ShareFileList } from "./shareFileList"
import authService from "../../services/AuthService"
import { ApiError } from "../../services/ApiService"

interface FileListModalProps {
  share: Share
  onClose: () => void
}

const FileListModal = ({ share, onClose }: FileListModalProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<Share["files"]>()

  useEffect(() => {
    if (share) {
      setIsLoading(true)
      getShareFiles(share.id)
    }
  }, [share])

  const downloadUrl = () => {
    const url = shareService.downloadZipUrl + share.id + "/download/"
    fetch(url, {
      method: "POST",
      headers: { Authorization: "Bearer " + authService.tokens?.access },
    })
      .then((resp) => {
        return resp.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = share.code + ".zip"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
  }

  const getShareFiles = async (id: Share["id"]) => {
    const result = await shareService.filesByID(id)
    if (result instanceof ApiError) {
      setIsLoading(false)
      setIsError(true)
    } else {
      setIsError(false)
      setFiles(result)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <ShareFileList files={files || []} />
        )}
        {isError && (
          <Alert variant="danger">
            Something went wrong. Cannot fetch the data at this moment
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Link to={pathList.view.reverse({ code: share.code })}>
          <Button className="btn btn-primary">View Files Online</Button>
        </Link>
        <Button className="btn btn-primary" onClick={downloadUrl}>
          Download
        </Button>
        <Button className="btn btn-primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FileListModal
