import React from "react"
import userTick from "./icons/user-tick.svg"
import Button from "../../components/Button"
import "bootstrap/dist/css/bootstrap.min.css"
import { pathList } from "../../routes/pathList"
import { useNavigate } from "react-router-dom"

const Landing = () => {
  const navigate = useNavigate()

  const handleAdminClick = () => {
    navigate(pathList.login.reverse())
  }
  const handlePatientClick = () => {
    navigate(pathList.lookup.reverse())
  }

  return (
    <>
      <div className="pt-4">
        <Button
          icon={userTick}
          variant="light"
          className="w-100 d-flex align-items-center"
          onClick={handleAdminClick}
          label={"Continue as Admin"}
        />
      </div>
      <div className="pt-2">
        <Button
          icon={userTick}
          variant="light"
          className="w-100 d-flex align-items-center"
          onClick={handlePatientClick}
          label={"Continue as Patient"}
        />
      </div>
    </>
  )
}

export default Landing
