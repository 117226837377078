import { BlobCacheElement, cache } from "./Cache"
import { parseDicomMetadata } from "./DicomMetadata"
import { Instance } from "./types"
import { getAndStripAuthHeadersFromURL } from "./utils"

export function makeDicomUrl(
  instance: Instance,
  path: string,
  dicomEndpoint: string
): string {
  const parts = []
  parts.push(
    dicomEndpoint.endsWith("/") ? dicomEndpoint.slice(0, -1) : dicomEndpoint
  )
  parts.push("studies/" + instance.studyId)
  parts.push("series/" + instance.seriesId)
  parts.push("instances/" + instance.instanceId)
  if (path.startsWith("/")) {
    path = path.slice(1)
  }
  parts.push(path)
  return parts.join("/")
}

export const getImageStack = (
  instance: Instance,
  dicomEndpoint: string,
  batchSize = 15
) => {
  const imageUrls = [...Array(instance.frames).keys()].map((i) =>
    makeDicomUrl(instance, `frames/${i + 1}/rendered`, dicomEndpoint)
  )
  const loadedImages: ImageBitmap[] = []

  const allImagesPromise = async () => {
    let ofs = 0

    async function prefetchImage(idx: number) {
      const { blob }: BlobCacheElement = await cache.getAsync(imageUrls[idx])
      loadedImages[idx] = await createImageBitmap(blob)
    }

    while (ofs < imageUrls.length) {
      const batch: Promise<void>[] = []
      while (batch.length < batchSize && ofs < imageUrls.length) {
        batch.push(prefetchImage(ofs))
        ofs++
      }
      await Promise.all(batch)
    }
    return loadedImages
  }

  async function getMetadata() {
    const metadataUrl = makeDicomUrl(instance, "metadata", dicomEndpoint)
    const urlInfoObj = getAndStripAuthHeadersFromURL(metadataUrl, {
      Accept: "application/json",
    })
    const response = await fetch(urlInfoObj.url, {
      headers: urlInfoObj.headers,
    })
    const metadata = await response.json()
    return parseDicomMetadata(metadata)
  }

  async function getFirstFrame() {
    const { blob }: BlobCacheElement = await cache.getAsync(imageUrls[0])
    return await createImageBitmap(blob)
  }

  function getPercentLoaded() {
    const numLoaded = loadedImages.filter((img) => img !== undefined).length
    return imageUrls.length > 0 ? numLoaded / imageUrls.length : 0
  }

  const getThumbnailUrl = async (): Promise<string> => {
    const url = makeDicomUrl(instance, "frames/1/rendered", dicomEndpoint)
    return cache
      .getAsync(url)
      .then(({ blob }) => window.URL.createObjectURL(blob))
  }

  return {
    getMetadata,
    getFirstFrame,
    getPercentLoaded,
    getFrameImages: () => allImagesPromise(),
    getThumbnailUrl,
  }
}
