import React from "react"
import { ViewProvider } from "./context"
import s from "./DicomViewer.module.scss"
import { ImageViewArea } from "./ImageViewArea"
import { ThumbnailsCanDelete } from "./Thumbnails"
import { ImageStackFactory, Instance } from "./types"
import { ShareFile } from "../../services/ShareService/types"
import { MainToolbar } from "./MainToolbar"
import { OhifViewer } from "./OhifViewer"

interface OwnProps {
  instances: Instance[]
  files: ShareFile[]
  getImageStack: ImageStackFactory
  isDefaultToolbar: boolean
  isOhifActive?: boolean
  ohifUrl?: string
  hasUser: boolean
}

export const DicomViewer = ({
  instances,
  files,
  getImageStack,
  isDefaultToolbar,
  isOhifActive,
  ohifUrl,
  hasUser,
}: OwnProps) => {
  const viewerRef = React.useRef(null)

  return (
    <ViewProvider
      instances={instances}
      files={files}
      getImageStack={getImageStack}
      badInstancesCount={0}
    >
      <div className={s.wrapper} ref={viewerRef}>
        <MainToolbar
          ohifViewer={isOhifActive ?? false}
          isDefaultToolbar={isDefaultToolbar}
          badInstancesCount={0}
          hasUser={hasUser}
        />
        {isOhifActive ? (
          <OhifViewer objects={instances} ohifUrl={ohifUrl ?? ""} />
        ) : (
          <ImageViewArea
            isDefaultToolbar={isDefaultToolbar}
            viewer={viewerRef}
          />
        )}
        {!isOhifActive && <ThumbnailsCanDelete />}
      </div>
    </ViewProvider>
  )
}
