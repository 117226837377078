import React, { forwardRef } from "react"
import { Spinner, Table } from "react-bootstrap"
import { getDicomImageUrl } from "./patientFiles"
import s from "./patientFiles.module.scss"
import c from "classnames"
import pdfIcon from "./assets/icon/pdf-icon.png"
import downloadIcon from "./assets/imgs/downloadIcon.png"
import eyeIcon from "./assets/imgs/eyeIcon.png"
import { Instance, Study } from "../DicomViewer/types"
import { ShareFile } from "../../services/ShareService/types"
import { formatDate } from "./utils"

interface TableViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRows: any[]
  isFileDownloading: boolean
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleEyeIconClickForStudy: (studyId: Study["studyId"]) => void
  handleEyeIconClickForFile: (file: ShareFile) => void
  handleStudyDownloadClick: (studyId: Study["studyId"]) => void
  handleFileDownloadClick: (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"]
  ) => void
}

export const TableView = forwardRef<HTMLDivElement, TableViewProps>(
  (
    {
      currentRows,
      handleImageError,
      handleEyeIconClickForStudy,
      handleEyeIconClickForFile,
      handleStudyDownloadClick,
      handleFileDownloadClick,
      isFileDownloading,
    },
    ref
  ) => {
    const [downloadingIndex, setDownloadingIndex] = React.useState(0)

    const handleStudyDownload = (
      studyId: Instance["studyId"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleStudyDownloadClick(studyId)
    }

    const handleFileDownload = (
      link: ShareFile["link"],
      originalName: ShareFile["originalName"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleFileDownloadClick(link, originalName)
    }

    return (
      <div className={c(s.tableContainer)} ref={ref}>
        <Table className={c(s.table, "w-100")}>
          <thead className="dark-bg text-center">
            <tr className="font-size">
              <th colSpan={2}>FILES</th>
              <th colSpan={2}>STUDY DATE</th>
              <th colSpan={2}>MODALITY</th>
              <th colSpan={2}>BODY PART</th>
              <th colSpan={3}>DESCRIPTION</th>
              <th colSpan={1}># FILES</th>
              <th colSpan={4} className="text-center">
                OPTIONS
              </th>
            </tr>
          </thead>
          <tbody className="font-size text-center">
            {currentRows.map((item, index) => (
              <tr className={s.tableRow} key={item.studyId || item.id}>
                {item.studyId ? (
                  <>
                    {/* Study rows */}
                    <td colSpan={2}>
                      <img
                        className={s.thumbnail}
                        src={getDicomImageUrl(item.instances[0])}
                        onError={handleImageError}
                        alt="File Thumbnail"
                      />
                    </td>
                    <td colSpan={2}>
                      {item.date ? formatDate(item.date) : "Not Available"}
                    </td>
                    <td colSpan={2}>{item.instances[0].modality || "N/A"}</td>
                    <td colSpan={2}>{item.bodyPart || "Not Available"}</td>
                    <td colSpan={3}>
                      {item.studyDescription || "Not Available"}
                    </td>
                    <td colSpan={1}>{item.count}</td>
                    <td colSpan={4}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <img
                            className="w-auto m-2"
                            src={eyeIcon}
                            alt="eye Icon"
                            onClick={() =>
                              handleEyeIconClickForStudy(item.studyId)
                            }
                          />
                          <span>View</span>
                        </div>
                        {downloadingIndex === index && isFileDownloading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="m-2"
                          />
                        ) : (
                          <div>
                            <img
                              className="w-auto m-md-2"
                              src={downloadIcon}
                              alt="Download Icon"
                              onClick={() =>
                                handleStudyDownload(item.studyId, index)
                              }
                            />
                            <span>Download</span>
                          </div>
                        )}
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    {/* File rows */}
                    <td colSpan={2}>
                      <img
                        src={
                          item.contentType?.split("/")[1].toLowerCase() ===
                          "pdf"
                            ? pdfIcon
                            : item.link
                        }
                        alt="File Thumbnail"
                        onError={handleImageError}
                      />
                    </td>
                    <td colSpan={2}>Not Available</td>
                    <td colSpan={2}>N/A</td>
                    <td colSpan={2}>Not Available</td>
                    <td colSpan={3}>
                      {item.contentType?.split("/")[1].toUpperCase() + " File"}
                    </td>
                    <td colSpan={1}>1</td>
                    <td colSpan={4}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <img
                            className="w-auto m-2"
                            src={eyeIcon}
                            alt="eye Icon"
                            onClick={() => handleEyeIconClickForFile(item)}
                          />
                          <span>View</span>
                        </div>
                        {downloadingIndex === index && isFileDownloading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="m-2"
                          />
                        ) : (
                          <div>
                            <img
                              className="w-auto m-md-2"
                              src={downloadIcon}
                              alt="Download Icon"
                              onClick={() =>
                                handleFileDownload(
                                  item.link,
                                  item.originalName,
                                  index
                                )
                              }
                            />
                            <span>Download</span>
                          </div>
                        )}
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
)
