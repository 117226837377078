import React, { useCallback } from "react"
import { useUser } from "../../../hooks"
import { ApiError } from "../../../services/ApiService"
import shareService from "../../../services/ShareService"
import { Displayable } from "../../../services/ShareService/types"
import { isSingleImageAndStack } from "../../../utils"
import { useDispatch } from "../context"
import { Instance } from "../types"
import { Thumbnails } from "./Thumbnails"
import { RemoveObjectIcon } from "./RemoveObjectIcon"

export interface ActionProps {
  object: Displayable
  code?: string
  selectedInstanceId?: Displayable["id"]
}

const DeleteAction = ({ object, code, selectedInstanceId }: ActionProps) => {
  const { state, setObject, setInstances, setFiles } = useDispatch()
  const { instances, files } = state

  const deleteFileOrInstances = useCallback(
    async (object: Displayable) => {
      if (!code) return
      if (object.contentType === "dicom") {
        const obj = object as Instance
        const response = await shareService.deleteInstances(
          obj.pk,
          code,
          !(isSingleImageAndStack(obj) || obj.frames > 1)
        )
        if (response instanceof ApiError) {
          alert(
            "Error deleting file. Please contact technical support if the problem persists"
          )
          return
        }
        const newInstances = instances.filter(
          (instance) => instance.pk !== obj.pk
        )
        setInstances(newInstances)
        if (
          newInstances &&
          obj.pk === Number(selectedInstanceId?.split("-")[1])
        ) {
          setObject(newInstances[0])
        }
      } else {
        try {
          await shareService.deleteFile(object.pk, code)
          const newFiles = files.filter((file) => file.pk !== object.pk)
          setFiles(newFiles)
          if (
            newFiles &&
            object.pk === Number(selectedInstanceId?.split("-")[1])
          ) {
            setObject(newFiles[0])
          }
        } catch (error) {
          alert(
            "Error deleting file. Please contact technical support if the problem persists"
          )
        }
      }
    },
    [
      code,
      files,
      instances,
      selectedInstanceId,
      setFiles,
      setInstances,
      setObject,
    ]
  )

  return <RemoveObjectIcon object={object} onDelete={deleteFileOrInstances} />
}

export const ThumbnailsCanDelete = () => {
  const user = useUser()
  if (!user) {
    return <Thumbnails />
  }
  return <Thumbnails action={(props) => <DeleteAction {...props} />} />
}
