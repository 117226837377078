import React, { useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Key } from "ts-key-enum"
import { Displayable } from "../../../services/ShareService/types"
import { useDispatch } from "../context"
import { useKeyboardHandler } from "../keyboard"
import { Instance } from "../types"
import { FileViewer } from "./FileViewer"
import { ActionProps } from "./ThumbnailsCanDelete"
import s from "./Thumbnails.module.scss"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  action?: (props: ActionProps) => JSX.Element
}

export const Thumbnails = ({ action }: Props) => {
  const { state, setObject } = useDispatch()
  const { instances, files, layout } = state
  const { code } = useParams()
  const divRef = useRef<HTMLDivElement>(null)

  const numPanels = layout.rows * layout.cols

  const objects: Displayable[] = React.useMemo(
    () => [...instances, ...files],
    [instances, files]
  )
  useEffect(() => {
    if (objects.length) {
      setTimeout(() => {
        setObject(objects[0])
      }, 2000)
    }
  }, [objects, setObject])

  const selectedInstanceId = React.useMemo(
    () => state.panels.find((ps) => ps.isSelected)?.object?.id,
    [state.panels]
  )
  const visibleInstanceIds = React.useMemo(
    () =>
      state.panels
        .map((ps) => ps.object?.id)
        .filter((instanceId) => !!instanceId) as Instance["instanceId"][],
    [state.panels]
  )

  const moveDirection = React.useCallback((index: number) => {
    divRef.current?.children[index]?.scrollIntoView({
      behavior: "auto",
      inline: "center",
    })
  }, [])

  const nextThumbnail = React.useCallback(() => {
    // find first visible thumbnail
    const firstVisibleIdx = objects.findIndex((object) =>
      visibleInstanceIds.includes(object.id)
    )
    // find first non-visible thumbnail AFTER the first visible thumbnail
    let targetIdx = objects.findIndex(
      (object, idx) =>
        !visibleInstanceIds.includes(object.id) && idx > firstVisibleIdx
    )
    if (targetIdx === -1) {
      return // we're at the end of the stack
    }
    if (targetIdx > objects.length - numPanels) {
      targetIdx = objects.length - numPanels // this is the end
    }
    for (let i = 0; i < numPanels; i++) {
      setObject(objects[targetIdx + i], i)
    }
    if (objects.length - 1 !== targetIdx) moveDirection(targetIdx)
  }, [objects, numPanels, moveDirection, visibleInstanceIds, setObject])

  const prevThumbnail = React.useCallback(() => {
    // find first visible thumbnail
    const firstVisibleIdx = objects.findIndex((object) =>
      visibleInstanceIds.includes(object.id)
    )
    let targetIdx =
      firstVisibleIdx < numPanels ? 0 : firstVisibleIdx - numPanels
    if (targetIdx > objects.length - numPanels) {
      targetIdx = objects.length - numPanels // this is the end
    }
    for (let i = 0; i < numPanels; i++) {
      setObject(objects[targetIdx + i], i)
    }
    moveDirection(targetIdx)
  }, [objects, numPanels, moveDirection, visibleInstanceIds, setObject])

  useKeyboardHandler(Key.ArrowRight, nextThumbnail)
  useKeyboardHandler(Key.ArrowLeft, prevThumbnail)

  useEffect(() => {
    if (objects && !selectedInstanceId) {
      setObject(objects[0], 0)
    }
  }, [objects, selectedInstanceId, setObject])

  const whichBorder = React.useCallback(
    (instance: Displayable) => {
      if (instance.id === selectedInstanceId) return "3px solid red"
      const visible = visibleInstanceIds.includes(instance.id)
      return visible ? "3px solid green" : "0px"
    },
    [selectedInstanceId, visibleInstanceIds]
  )

  return (
    <div className={s.carouselContainer}>
      <Button variant="light" onClick={prevThumbnail}>
        <FontAwesomeIcon icon="circle-chevron-left" />
      </Button>
      <div className={s.wrapper} ref={divRef}>
        {objects?.map((object) => (
          <div
            className={s.imageThumb}
            key={object.id}
            style={{
              border: whichBorder(object),
            }}
          >
            <FileViewer object={object} setObject={setObject} />
            {action?.({
              object,
              code,
              selectedInstanceId,
            })}
          </div>
        ))}
      </div>
      <Button variant="light" onClick={nextThumbnail}>
        <FontAwesomeIcon icon="circle-chevron-right" />
      </Button>
    </div>
  )
}
