import { AxiosError } from "axios"
import { useRouteError } from "react-router-dom"
import s from "./error.module.scss"

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)
  let message: string
  if (error instanceof AxiosError) {
    if (error.code === "ERR_NETWORK") {
      message =
        "Cannot reach the server. Check your internet connection and try again in a few minutes."
    } else if (error.response?.status === 403) {
      message =
        "You do not have permission to view this page. Please contact technical support if you believe this is an error."
    } else {
      message = error.message
    }
  } else if (error instanceof Error) {
    message = error.message
  } else {
    message = JSON.stringify(error)
  }

  return (
    <div className={s.wrapper}>
      <h1>Something went wrong.</h1>
      <p>{message}</p>
    </div>
  )
}
