import React from "react"
import s from "./Image.module.scss"
import { ImageFile } from "../types"

interface ImageProp {
  link: ImageFile["link"]
  id: ImageFile["id"]
}

const Image = ({ link, id }: ImageProp) => {
  return <img className={s.image} src={link} alt={id.toString()} />
}

export default Image
