import React from "react"
import { Button, Spinner } from "react-bootstrap"
import s from "./button.module.scss"
import c from "classnames"

interface CustomButtonProps {
  icon?: string
  label: string
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
  onClick?: () => void
  className?: string
  buttonType?: "button" | "submit" | "reset" | undefined
  name?: string
  centerLabel?: boolean // Add this prop
  disabled?: boolean
  showSpinner?: boolean
}
const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      icon,
      label,
      variant,
      onClick,
      className,
      buttonType = "button",
      name,
      centerLabel = false,
      disabled = false,
      showSpinner = false,
    },
    ref
  ) => {
    return (
      <Button
        variant={variant}
        onClick={onClick}
        className={c(className, s.customButton)}
        type={buttonType}
        name={name}
        ref={ref}
        disabled={disabled}
      >
        {showSpinner && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {icon && <img src={icon} alt={label} className="button-icon me-2" />}
        <span className={c(centerLabel ? "m-auto" : "", "button-label mb-0")}>
          {label}
        </span>
      </Button>
    )
  }
)

export default CustomButton
