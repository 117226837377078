import React from "react"
import s from "./Video.module.scss"
import { VideoFile } from "../types"

interface VideoProp {
  link: VideoFile["link"]
  id: VideoFile["id"]
  isThumbnail?: boolean
}

const Video = ({ id, link, isThumbnail = false }: VideoProp) => {
  return <video className={s.video} src={link} controls={!isThumbnail} muted />
}

export default Video
