import React from "react"
import { Row, Col } from "react-bootstrap"
import Button from "../Button"
import Window from "./assets/icon/Windows_logo_-_2012 1.svg"
import Apple from "./assets/icon/apple-logo-13-1024x1024 1.svg"
import info from "./assets/icon/info-circle.png"
import closeIcon from "./assets/icon/close-circle.png"

interface Props {
  onClose: () => void
  isSmallScreenActive?: boolean
}

const HelpContent = ({ onClose, isSmallScreenActive = false }: Props) => {
  return (
    <>
      <Row className="justify-content-center">
        {!isSmallScreenActive && (
          <>
            {" "}
            <Col md={6} className="d-flex align-items-center">
              <img src={info} alt="info-icon" />
              <span className="fs-6 dark-color p-1"> Help</span>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <img src={closeIcon} alt="info-icon" onClick={onClose} />
            </Col>
          </>
        )}
      </Row>
      <p className="fw-bold font-size">
        The file delivered is a ZIP file that contains a number of DICOM files.
        DICOM files cannot be read without a helper program. Please follow the
        steps below to view:
      </p>
      <p className="text-muted font-size p-1 m-0 fw-medium">
        1. Unzip the contents of the file into a directory using your computer's
        native tools.
      </p>
      <p className="text-muted font-size p-1 m-0 fw-medium">
        2. Install a DICOM viewer appropriate to your type of computer.
      </p>
      <p className="text-muted font-size p-1 m-0 fw-medium">
        3. Open the DICOM viewer and load the directory containing the extracted
        files, or drag and drop the directory onto the opened program.
      </p>

      <p>
        NOTE: The two suggested DICOM viewers below are suggestions and have no
        affiliation with MedIQ
      </p>
      <Row className="text-center mt-1">
        <Col md={6} sm={12}>
          <a
            href="https://www.microdicom.com/downloads.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              icon={Window}
              label="Download For Windows"
              className={`font-size w-100 ${isSmallScreenActive && "mb-2"}`}
              variant="light"
            />
          </a>
        </Col>
        <Col md={6} sm={12}>
          <a
            href="https://horosproject.org/download-horos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              icon={Apple}
              label="Download For MacOS"
              className="font-size w-100"
              variant="light"
            />
          </a>
        </Col>
      </Row>
    </>
  )
}

export default HelpContent
