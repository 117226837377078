import axios, {
  Axios,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios"
import { camelizeKeys, decamelize, decamelizeKeys } from "humps"
import { ApiError } from "./ApiService"
import authService from "./AuthService"

function jsonify(value: unknown): unknown {
  if (typeof value == "string") {
    return JSON.parse(value)
  }
  return value
}

interface RetryableAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
}

interface RetryableAxiosError extends Omit<AxiosError, "config"> {
  config: RetryableAxiosRequestConfig
}

export abstract class BaseApiService {
  protected apiClient: AxiosInstance
  protected baseUrl = ""

  constructor() {
    const client = (this.apiClient = axios.create({
      baseURL: window.location.origin,
      headers: {
        "Content-Type": "application/json",
      },
    }))
    client.interceptors.request.use((config) =>
      this.axiosRequestSuccess(config)
    )
    client.interceptors.response.use(
      (response) => this.axiosResponseSuccess(response),
      (error) => this.axiosResponseFailure(error)
    )
  }

  public serverUrl(path?: string): string {
    if (!path) {
      return this.baseUrl
    }
    if (path.startsWith("http") || path.startsWith("//")) {
      return path
    }
    if (path.startsWith("/")) {
      path = path.slice(1)
    }
    return this.baseUrl + path
  }

  protected get client(): Axios {
    return this.apiClient
  }

  protected async axiosResponseSuccess(response: AxiosResponse) {
    if (
      response.data &&
      response.headers["content-type"] === "application/json"
    ) {
      response.data = camelizeKeys(response.data)
    }
    return response
  }

  protected async axiosResponseFailure(error: RetryableAxiosError) {
    const config = error.config
    if (error.response?.status === 401 && authService.tokens) {
      if (config?._retry) {
        authService.tokens = undefined
        return Promise.reject(error)
      }
      config._retry = true
      const newTokens = await authService.refreshAccessToken()
      if (!newTokens || newTokens instanceof ApiError) {
        return Promise.reject(error)
      }
      if (!config.headers) {
        config.headers = {}
      }
      config.headers["Authorization"] = "Bearer " + newTokens.access
      return this.apiClient(config)
    }
    return Promise.reject(error)
  }

  protected async axiosRequestSuccess(
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    const newConfig: InternalAxiosRequestConfig = { ...config }
    newConfig.url = this.serverUrl(newConfig.url)
    if (!newConfig.headers) {
      newConfig.headers = {} as AxiosRequestHeaders
    }
    newConfig.headers["Accept"] ??= "application/json"
    if (hasFormData(newConfig.data)) {
      newConfig.headers["Content-Type"] = "multipart/form-data"
    }
    if (!newConfig.headers["Authorization"]) {
      const access = authService.tokens?.access
      if (access) {
        newConfig.headers["Authorization"] = `Bearer ${access}`
      }
    }
    if (newConfig.data) {
      if (newConfig.data instanceof FormData) {
        const newData = new FormData()
        for (const [name, value] of newConfig.data.entries()) {
          const snakeName = decamelize(name)
          newData.append(snakeName, value)
        }
        newConfig.data = newData
      } else {
        const entries = Object.entries(newConfig.data).map(([k, v]) => [
          decamelize(k),
          v,
        ])
        newConfig.data = Object.fromEntries(entries)
      }
    }
    if (newConfig.params) {
      newConfig.params = decamelizeKeys(jsonify(newConfig.params))
    }
    return newConfig
  }
}

function hasFormData(data: {
  [k: string]: FormDataEntryValue | FormDataEntryValue[]
}) {
  if (data instanceof FormData) {
    for (const v of data.values()) {
      if (v instanceof File) {
        return true
      }
    }
  } else {
    for (const key in data) {
      const val = data[key]
      if (val instanceof File) {
        return true
      }
      if (val instanceof Array && val.some((v) => v instanceof File)) {
        return true
      }
    }
  }
  return false
}
