import { ParsedDicomMetadata } from "./DicomMetadata"
import { Displayable, ShareFile } from "../../services/ShareService/types"

export enum ImageType {
  PNG = "image/png",
  JPG = "image/jpeg",
  GIF = "image/gif",
}

export type Image = keyof Record<ImageType, string>

export interface Instance extends Displayable {
  // id: string // app-level object identifier
  contentType: "dicom"
  studyId: string
  seriesId: string
  instanceId: string
  frames: number
  modality: string
  date: string
}

export interface Study {
  count: number
  instances: Instance[]
  studyId: Instance["studyId"]
  bodyPart: string
  studyDescription: string
  protocolName: string
  date: string
}

export interface PdfFile extends ShareFile {
  contentType: "application/pdf"
}

export interface ImageFile extends ShareFile {
  contentType: Image
}

export interface VideoFile extends ShareFile {
  contentType: "video/mp4"
}

export interface Point {
  x: number
  y: number
}

export interface DicomImageStack {
  getFirstFrame: () => Promise<ImageBitmap>
  getPercentLoaded: () => number
  getFrameImages: () => Promise<ImageBitmap[]>
  getMetadata: () => Promise<ParsedDicomMetadata>
  getThumbnailUrl: () => Promise<string>
}

export type ImageStackFactory = {
  (instance: Instance, batchSize?: number): DicomImageStack
}
