import React from "react"
import { RouteObject } from "react-router-dom"
import { pathList } from "./pathList"
import Create from "../pages/create"
import Edit from "../pages/edit"
import List from "../pages/list"
import View from "../pages/view"
import AuthRequired from "../components/AuthRequired"

const routes: RouteObject[] = [
  {
    element: <AuthRequired />,
    children: [
      {
        path: pathList.list.path,
        element: <List />,
        loader: List.loader,
      },
      {
        path: pathList.create.path,
        element: <Create />,
      },
      {
        path: pathList.edit.path,
        element: <Edit />,
        loader: Edit.loader,
        action: Edit.action,
      },
      {
        path: pathList.view.path,
        element: <View />,
        loader: View.loader,
      },
    ],
  },
]
export default routes
