import React from "react"
import { Button } from "react-bootstrap"
import { ToolbarButton } from "./ToolbarButton"

interface LayoutButtonProps {
  viewer: React.RefObject<HTMLElement>
}

export const LayoutButton = ({ viewer }: LayoutButtonProps) => {
  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      viewer.current?.requestFullscreen()
    }
  }

  return (
    <ToolbarButton title="Layout" icon="none">
      <Button variant="secondary" onClick={toggleFullscreen} size="sm">
        Fullscreen
      </Button>
    </ToolbarButton>
  )
}
