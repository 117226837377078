import React from "react"

interface OwnProps {
  children: React.ReactNode
  title: string
  icon: string
}

export const ToolbarButton = ({ children, title, icon }: OwnProps) => {
  return <>{children}</>
}
