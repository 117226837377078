import { drawFrame } from "./drawing"

export interface CinePlayer {
  play: () => void
  stop: () => void
  isPlaying: () => boolean
  currentFrame: () => number
  nextFrame: () => void
  prevFrame: () => void
  lastFrame: () => void
  firstFrame: () => void
}

export function makeCinePlayer(
  canvas: HTMLCanvasElement,
  images: ImageBitmap[],
  defaultFps: number
): CinePlayer {
  let currFrame = 0
  const fps = defaultFps
  let handle: number
  let isPlaying = false

  const movie = () => {
    if (currFrame < images.length - 1) currFrame++
    else currFrame = 0
    // drawFrame(canvas, images, currFrame);
  }

  function play() {
    if (!isPlaying) {
      console.log(`fps: ${fps}`)
      isPlaying = true
      handle = window.setInterval(movie, (1 / fps) * 1000)
    }
  }

  function stop() {
    isPlaying = false
    clearInterval(handle)
  }

  function setFrame(frame: number) {
    stop()
    currFrame = frame
    drawFrame(canvas, images, currFrame)
  }

  return {
    play,
    stop,
    isPlaying: () => isPlaying,
    currentFrame: () => currFrame,
    nextFrame: () =>
      setFrame(
        currFrame < images.length - 1 ? currFrame + 1 : images.length - 1
      ),
    prevFrame: () => setFrame(currFrame > 0 ? currFrame - 1 : 0),
    firstFrame: () => setFrame(0),
    lastFrame: () => setFrame(images.length - 1),
  }
}
