import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { pathList } from "../../routes/pathList"
import { Share } from "../../services/ShareService/types"
import { formatShareURL } from "../../utils"
import s from "./index.module.scss"
import { useUser } from "../../hooks"

interface TableBodyProps {
  items: Share[]
  showFiles: (share: Share) => void
}

interface ShareLinkProps {
  share: Share
}

const ShareLink = ({ share }: ShareLinkProps) => {
  const url = formatShareURL(share.code)
  return (
    <>
      <p className="m-0">{url}</p>
      <Button
        className="mx-1"
        onClick={() => {
          navigator.clipboard.writeText(url)
        }}
      >
        Copy
      </Button>
    </>
  )
}

export const TableBody = ({ items, showFiles }: TableBodyProps) => {
  const user = useUser()
  if (!items.length) {
    return (
      <tr>
        <td colSpan={11} className={s.empty}>
          No entries
        </td>
      </tr>
    )
  }

  return (
    <>
      {items.map((share, i) => (
        <tr key={i}>
          <td>
            {share.firstName} {share.lastName}
          </td>
          <td>{share.mrnumber}</td>
          <td>{share.dob}</td>
          <td>{share.status}</td>
          <td>{share.created.toLocaleString()}</td>
          <td>{share.expires.toLocaleString()}</td>
          <td>
            {share.downloaded
              ? `${share.downloaded} time${share.downloaded > 1 ? "s" : ""}`
              : "no"}
          </td>
          {(user?.units.length ?? 0) > 1 && (
            <td>
              {user?.units.find((unit) => unit.id === share.unitId)?.name}
            </td>
          )}
          <td>
            <Button onClick={() => showFiles(share)}>show</Button>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <ShareLink share={share} />
            </div>
          </td>
          <td>
            <Link to={pathList.edit.reverse({ id: share.id })}>Edit</Link>
          </td>
        </tr>
      ))}
    </>
  )
}
