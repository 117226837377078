import React, { useRef, useState, useEffect } from "react"
import s from "./patientFiles.module.scss"
import Button from "../Button"
import { Col, Modal, Overlay, Pagination, Popover, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { Share, ShareFile } from "../../services/ShareService/types"
import { Instance, Study } from "../DicomViewer/types"
import shareService from "../../services/ShareService"
import { convertDateFormat } from "../../utils"
import { BaseView } from "../../pages/view"
import defaultImage from "../../assets/imgs/defaultFile.png"
import c from "classnames"
import { makeDicomUrl } from "../DicomViewer/DicomStack"
import { API_HOSTNAME } from "../../config"
import HelpContent from "./HelpContent"
import { TableView } from "./TableView"
import { CollectionView } from "./CollectionView"

interface PatientFilesListingProps {
  shareFiles: ShareFile[]
  isSmallScreen: boolean
}

export const getDicomImageUrl = (instance: Instance): string => {
  return makeDicomUrl(
    instance,
    "frames/1/rendered",
    `${API_HOSTNAME}api/share/dicom/`
  )
}

const PatientFilesListing: React.FC<PatientFilesListingProps> = ({
  shareFiles,
  isSmallScreen,
}) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const location = useLocation()
  const share: Share = location.state.share || {}
  const studies = location.state.studies || {}

  const [isAllFilesDownloading, setIsAllFilesDownloading] =
    React.useState(false)
  const [isFileDownloading, setIsFileDownloading] = React.useState(false)
  const [showViewer, setShowViewer] = React.useState(false)
  const [instances, setInstances] = useState<Instance[]>([])
  const [files, setFiles] = useState<ShareFile[]>([])

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [totalPages, setTotalPages] = useState(1)
  const tableContainerRef = useRef<HTMLDivElement | null>(null)

  const allFiles = [...studies, ...shareFiles] // Combine studies and files for pagination

  const downloadFiles = async () => {
    setIsAllFilesDownloading(true)

    await shareService.filesDownload({
      code: share.code,
      dob: convertDateFormat(share.dob),
    })

    setIsAllFilesDownloading(false)
  }

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = event.target as HTMLImageElement
    target.src = defaultImage
  }

  const handleStudyDownloadClick = async (studyId: Instance["studyId"]) => {
    setIsFileDownloading(true)
    await shareService.downloadStudy(studyId, share.code, share.dob)
    setIsFileDownloading(false)
  }

  const handleFileDownloadClick = async (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"]
  ) => {
    setIsFileDownloading(true)
    await shareService.downloadFile(link, originalName)
    setIsFileDownloading(false)
  }

  // Dynamically calculate the number of rows that can fit in the table based on height
  useEffect(() => {
    const updateRowsPerPage = () => {
      if (tableContainerRef.current) {
        const containerHeight = tableContainerRef.current.clientHeight
        const rowHeight = 60
        const calculatedRows = Math.floor(containerHeight / rowHeight)
        setRowsPerPage(calculatedRows)
        setTotalPages(Math.ceil(allFiles.length / calculatedRows))
      }
    }

    updateRowsPerPage()
    window.addEventListener("resize", updateRowsPerPage)

    return () => window.removeEventListener("resize", updateRowsPerPage)
  }, [allFiles.length])

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleViewAllImages = () => {
    const allInstances = studies.flatMap((study: Study) => study.instances) // Assuming `studies` is an array
    setInstances(allInstances)
    setFiles(shareFiles ?? [])
    setShowViewer(true)
  }

  const handleEyeIconClickForStudy = (studyId: string) => {
    const study = studies.find((study: Study) => study.studyId === studyId)
    if (study) {
      setInstances(study.instances)
      setFiles([])
      setShowViewer(true)
    }
  }

  const handleEyeIconClickForFile = (file: ShareFile) => {
    setInstances([])
    setFiles([file])
    setShowViewer(true)
  }

  // Handle pagination
  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRows = allFiles.slice(indexOfFirstRow, indexOfLastRow)

  // Render pagination with ellipsis
  const renderPaginationItems = () => {
    const items = []
    if (totalPages <= 5) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        )
      }
    } else {
      items.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      )
      if (currentPage > 3)
        items.push(<Pagination.Ellipsis key="start-ellipsis" />)

      const startPage = Math.max(2, currentPage - 1)
      const endPage = Math.min(totalPages - 1, currentPage + 1)

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        )
      }

      if (currentPage < totalPages - 2)
        items.push(<Pagination.Ellipsis key="end-ellipsis" />)

      items.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      )
    }

    return items
  }

  const renderHelp = () => (
    <Col md={6} className="order-sm-3 order-md-1">
      <div
        className={`d-flex align-items-center ${
          isSmallScreen && "justify-content-center"
        }`}
      >
        {" "}
        <Button
          variant="link"
          ref={target}
          className="text-decoration-none font-size font-family px-1"
          onClick={() => setShow(!show)}
          label="Help"
        />
        <p className="m-0">with Downloaded DICOM Files</p>
      </div>

      {isSmallScreen ? (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Help</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HelpContent onClose={() => setShow(false)} isSmallScreenActive />
          </Modal.Body>
        </Modal>
      ) : (
        <Overlay target={target.current} show={show} placement="left-end">
          {(props) => (
            <Popover
              id="popover-positioned-top"
              {...props}
              className={c(s.popover)}
            >
              <Popover.Body className={c(s.popoverBody)}>
                <HelpContent onClose={() => setShow(false)} />
              </Popover.Body>
            </Popover>
          )}
        </Overlay>
      )}
    </Col>
  )

  return (
    <div
      className={`${!isSmallScreen && `card`} shadow-sm border-0 mb-1 h-100`}
    >
      {!isSmallScreen ? (
        <TableView
          currentRows={currentRows}
          handleImageError={handleImageError}
          handleEyeIconClickForStudy={handleEyeIconClickForStudy}
          handleEyeIconClickForFile={handleEyeIconClickForFile}
          handleStudyDownloadClick={handleStudyDownloadClick}
          handleFileDownloadClick={handleFileDownloadClick}
          isFileDownloading={isFileDownloading}
          ref={tableContainerRef}
        />
      ) : (
        <CollectionView
          currentRows={currentRows}
          handleImageError={handleImageError}
          handleEyeIconClickForStudy={handleEyeIconClickForStudy}
          handleEyeIconClickForFile={handleEyeIconClickForFile}
          handleStudyDownloadClick={handleStudyDownloadClick}
          handleFileDownloadClick={handleFileDownloadClick}
          isFileDownloading={isFileDownloading}
          ref={tableContainerRef}
        />
      )}
      {!isSmallScreen && <hr />}
      <Row className="mt-2 px-3">
        {!isSmallScreen && renderHelp()}
        <Col
          className={`d-flex justify-content-${
            isSmallScreen ? "center" : "end"
          } order-md-2`}
          md={6}
          sm={12}
        >
          <Pagination>
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Pagination.Prev>
            {renderPaginationItems()}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Col>
      </Row>
      <Row className="m-1">
        <Col md={6} className="mb-2 order-sm-2 order-md-1">
          <Button
            label={`${isAllFilesDownloading ? "" : "Download All Images"}`}
            className="dark-bg btn w-100"
            onClick={downloadFiles}
            disabled={isAllFilesDownloading}
            showSpinner={isAllFilesDownloading}
          />
        </Col>
        <Col md={6} className="mb-2 order-sm-1 order-md-2">
          <Button
            label="View All Images Online"
            className="w-100"
            variant="warning"
            onClick={handleViewAllImages}
          />
        </Col>
        {isSmallScreen && renderHelp()}
      </Row>
      <Modal show={showViewer} fullscreen onHide={() => setShowViewer(false)}>
        <BaseView
          instances={instances}
          files={files}
          isOhifActive={share.isOhifActive}
          ohifUrl={share.ohifUrl}
        />
      </Modal>
    </div>
  )
}

export default PatientFilesListing
