import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import React from "react"
import { Col, Container, Form, Row, Table } from "react-bootstrap"
import {
  Form as ReactForm,
  Link,
  useLoaderData,
  useNavigation,
  useSubmit,
} from "react-router-dom"
import { pathList } from "../../routes/pathList"
import { ApiError, handleApiError } from "../../services/ApiService"
import shareService from "../../services/ShareService"
import { Share } from "../../services/ShareService/types"
import FileListModal from "./fileListModal"
import s from "./index.module.scss"
import { TableBody } from "./tableBody"
import { useUser } from "../../hooks"

interface LoaderFunctionReturn {
  shares: Share[]
  q: string
}

const List = () => {
  const { shares, q } = useLoaderData() as LoaderFunctionReturn
  const navigation = useNavigation()
  const submit = useSubmit()
  const searching =
    navigation.location &&
    new URLSearchParams(navigation.location.search).has("q")

  const [share, setShare] = React.useState<Share | undefined>()
  const handleShowClose = () => {
    setShare(undefined)
  }
  const showFiles = (share: Share) => {
    setShare(share)
  }
  const user = useUser()
  const hasMultipleUnits = (user?.units.length ?? 0) > 1

  return (
    <>
      <Container>
        <Row className={s.heading}>
          <Col sm={11}>
            <h1>Shared files</h1>
          </Col>
          <Col className="text-right">
            <Link
              to={pathList.create.reverse()}
              className="btn btn-outline-primary"
            >
              Create
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactForm role="search">
              <Form.Group className="input-group mb-3">
                <Form.Control
                  id="q"
                  className={searching ? "loading" : ""}
                  aria-label="Search"
                  placeholder="Search by name, file name, MR Number or code"
                  type="search"
                  name="q"
                  defaultValue={q}
                  onChange={(e) => {
                    const isFirstSearch = q === null
                    submit(e.currentTarget.form, {
                      replace: !isFirstSearch,
                    })
                  }}
                />
              </Form.Group>
              <div id="search-spinner" aria-hidden hidden={!searching} />
              <div className="sr-only" aria-live="polite"></div>
            </ReactForm>
          </Col>
        </Row>
        <Row>
          <Table bordered={true} striped={true} hover={true}>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">MR Number</th>
                <th scope="col">DOB</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th scope="col">Expires</th>
                <th scope="col">Downloaded</th>
                {hasMultipleUnits && <th scope="col">Unit</th>}
                <th scope="col">Files</th>
                <th scope="col">Share</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <TableBody items={shares} showFiles={showFiles} />
            </tbody>
          </Table>
        </Row>
      </Container>
      {share !== undefined && (
        <FileListModal share={share} onClose={handleShowClose} />
      )}
    </>
  )
}

List.loader = async ({
  request,
}: LoaderFunctionArgs): Promise<LoaderFunctionReturn | Response> => {
  const url = new URL(request.url)
  const q = url.searchParams.get("q") ?? ""
  const result = await shareService.list(q)
  if (result instanceof ApiError) {
    return handleApiError(result)
  }
  return { shares: result, q }
}

export default List
