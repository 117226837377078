import initProviders from "./helper/initProviders"
import initCornerstoneDICOMImageLoader from "./helper/initCornerstoneDICOMImageLoader"
import initVolumeLoader from "./helper/initVolumeLoader"
import { init as csRenderInit } from "@cornerstonejs/core"
import { init as csToolsInit } from "@cornerstonejs/tools"
import initWebImageLoader from "./helper/initwebLoader"
;(async () => {
  initProviders()
  initCornerstoneDICOMImageLoader()
  initWebImageLoader()
  initVolumeLoader()
  await csRenderInit()
  await csToolsInit()
})()
