import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { slides } from "../../pages/landing/slides"
import Slider from "../Slider/slider"
import s from "./OnboardingLayout.module.scss"
import c from "classnames"
import { Outlet } from "react-router-dom"
import { pathList } from "../../routes/pathList"
import logoSvg from "../../assets/imgs/logo.svg"

const OnboardingLayout = () => {
  return (
    <Container fluid className="w-100">
      <Row className="min-vh-100">
        <Col md={5} xl={4} sm={6} className="light-bg d-flex flex-column pt-4">
          <div className="login-wrapper pt-5 pb-5 ms-5 me-5">
            <a href={pathList.root.path} className="logo d-inline-block">
              <img src={logoSvg} alt="logo" />
            </a>
            <h2 className="logo-hd mt-3 mb-0 fw-bold dark-color">Patient</h2>
            <p className="logo-cap mt-1 grey-color">
              Information Management System
            </p>
            <Outlet />
          </div>
        </Col>
        <Col
          md={7}
          xl={8}
          sm={6}
          className={c(
            s.backgroundImage,
            "align-content-center position-relative overflow-hidden"
          )}
        >
          <div className="login-wrapper">
            <Slider slides={slides} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default OnboardingLayout
