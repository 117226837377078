import { matchSorter } from "match-sorter"
import { Instance, Study } from "../../components/DicomViewer/types"
import apiService, { ApiError } from "../ApiService"
import {
  PublicShare,
  PublicShareApiObject,
  Share,
  ShareApiObject,
  ShareUpdate,
  ShareFile,
  Response,
  FileRequestData,
} from "./types"

class ShareService {
  public readonly downloadUrl = apiService.serverUrl("/share/download/")

  public readonly downloadZipUrl = apiService.serverUrl("/share/")
  public async list(query?: string): Promise<Share[] | ApiError> {
    const response = await apiService.request({ endpoint: "/share/list/" })
    if (response instanceof ApiError) {
      return response
    }

    let shares = (response as ShareApiObject[]).map((o) => new Share(o))
    if (query) {
      shares = matchSorter(shares, query, {
        keys: ["firstName", "lastName", "mrnumber"],
      })
    }
    return shares
  }

  public async get(id: string): Promise<Share | ApiError | undefined> {
    const response = await apiService.request<ShareApiObject>({
      endpoint: `/share/${id}/`,
    })
    if (response instanceof ApiError) {
      if (response.notFound) {
        return
      }
      return response
    }
    return new Share(response)
  }

  public async create(share: FormData): Promise<Share | ApiError> {
    const response = await apiService.request<ShareApiObject>({
      endpoint: "/share/create/",
      method: "post",
      data: share,
      hasFiles: true,
    })
    if (response instanceof ApiError) {
      return response
    }
    return new Share(response)
  }

  public async uploadFile(
    file: FormData,
    shareId: string
  ): Promise<Response | ApiError> {
    const response = await apiService.request<Response>({
      endpoint: `/share/${shareId}/upload/`,
      method: "post",
      data: file,
      hasFiles: true,
    })
    return response
  }

  public async update(
    id: string,
    share: ShareUpdate
  ): Promise<Share | ApiError> {
    const response = await apiService.request<ShareApiObject>({
      endpoint: `/share/${id}/`,
      method: "put",
      data: share,
    })
    if (response instanceof ApiError) {
      return response
    }
    return new Share(response)
  }

  public async lookup(
    code: string,
    dob: string
  ): Promise<PublicShare | ApiError> {
    const response = await apiService.request<PublicShareApiObject>({
      endpoint: `/share/public/`,
      method: "post",
      data: { code, dob },
    })
    if (response instanceof ApiError) {
      return response
    }
    return new PublicShare(response)
  }

  public async patientLookup(
    code: string,
    dob: string
  ): Promise<PublicShare | ApiError> {
    const response = await apiService.request<PublicShareApiObject>({
      endpoint: `/share/public/`,
      method: "post",
      data: { code, dob },
    })
    if (response instanceof ApiError) {
      return response
    }
    return new PublicShare(response)
  }

  public async instances(code: string): Promise<Instance[] | ApiError> {
    const response = await apiService.request({
      endpoint: `/share/${code}/instances/`,
    })
    if (response instanceof ApiError) {
      return response
    }
    const data = response as Instance[]
    return data.map((item: Instance) => ({
      ...item,
      contentType: "dicom",
      id: `i-${item.pk}`,
    }))
  }

  public async deleteInstances(
    instanceId: number,
    code: string,
    deleteSeries: boolean
  ): Promise<Instance | ApiError> {
    return await apiService.request<Instance>({
      endpoint: `/share/${code}/instance/${instanceId}/?deleteSeries=${deleteSeries}`,
      method: "delete",
    })
  }

  public async studies(code: string): Promise<Study[] | ApiError> {
    const response = await apiService.request({
      endpoint: `/share/${code}/lookup/studies/`,
    })
    if (response instanceof ApiError) {
      return response
    }
    const data = response as Study[]
    return data.map((study: Study) => ({
      ...study,
      instances: study.instances.map((item: Instance) => ({
        ...item,
        contentType: "dicom",
        id: `i-${item.pk}`,
      })),
    }))
  }

  // change the type of Promise
  public async filesByID(id: string): Promise<ShareFile[] | ApiError> {
    const response = await apiService.request({
      endpoint: `/share/${id}/files/`,
    })
    if (response instanceof ApiError) {
      return response
    }
    const data = response as ShareFile[]
    return data.map((f: ShareFile) => {
      f.id = `f-${f.pk}`
      return f
    })
  }

  // change the type of Promise
  public async filesByCode(code: string): Promise<ShareFile[] | ApiError> {
    const response = await apiService.request({
      endpoint: `/share/${code}/codefiles/`,
    })
    if (response instanceof ApiError) {
      return response
    }
    const data = response as ShareFile[]
    return data.map((f: ShareFile) => {
      f.id = `f-${f.pk}`
      return f
    })
  }

  public async filesDownload(data: FileRequestData) {
    const response = await apiService.request<Blob>({
      endpoint: this.downloadUrl,
      method: "post",
      data,
      hasFiles: true,
      responseType: "blob",
    })

    if (response instanceof Blob) {
      // Create a temporary link element
      const link = document.createElement("a")
      link.href = URL.createObjectURL(new Blob([response]))
      link.setAttribute("download", "shared-files-all.zip") // Set the filename for the downloaded file

      document.body.appendChild(link)
      link.click()

      // Clean up the temporary link
      document.body.removeChild(link)
    } else {
      // Handle the case where the response is not a Blob (e.g., ApiError)
      console.error("Unexpected response type:", response)
      alert(response.originalError.message)
    }
  }

  public async deleteFile(
    fileId: number,
    code: string
  ): Promise<ShareFile | ApiError> {
    const response = await apiService.request<ShareFile>({
      endpoint: `/share/${code}/file/${fileId}/`,
      method: "delete",
    })
    return response
  }

  public async downloadFile(url: string, fileName: string): Promise<void> {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`)
      }
      const blob = await response.blob()
      const objectURL = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = objectURL
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(objectURL)
      document.body.removeChild(a)
    } catch (error) {
      console.error("Error downloading file:", error)
    }
  }

  public async downloadStudy(
    studyId: Instance["studyId"],
    code: Share["code"],
    dob: Share["dob"]
  ) {
    const response = await apiService.request<Blob>({
      endpoint: "/share/study/download/",
      method: "post",
      data: { study_id: studyId, code, dob },
      hasFiles: true,
      responseType: "blob",
    })

    if (response instanceof Blob) {
      // Create a temporary link element
      const link = document.createElement("a")
      link.href = URL.createObjectURL(new Blob([response]))
      link.setAttribute("download", `shared-files-${studyId}.zip`) // Set the filename for the downloaded file

      document.body.appendChild(link)
      link.click()

      // Clean up the temporary link
      document.body.removeChild(link)
    } else {
      // Handle the case where the response is not a Blob (e.g., ApiError)
      console.error("Unexpected response type:", response)
      alert(response.originalError.message)
    }
  }
}

const shareService = new ShareService()
export default shareService
