import React, { useState } from "react"
import { Container, Row, Alert } from "react-bootstrap"
import { Form as ReactForm, useNavigate } from "react-router-dom"
import { pathList } from "../../routes/pathList"
import { ApiError } from "../../services/ApiService"
import authService from "../../services/AuthService"
import userService from "../../services/UserService"
import Input from "../../components/Input/input"
import Button from "../../components/Button/index"

const useLogin = () => {
  const [formError, setFormError] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleLoginClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const formData = new FormData(form)
    const email = formData.get("email") as string
    const password = formData.get("password") as string
    try {
      const tokens = await authService.login({ email, password })
      if (!tokens || tokens instanceof ApiError) {
        setFormError("Email/password combination was not found.")
        return
      }
      authService.tokens = tokens
      const user = await userService.loadProfile()
      if (user instanceof ApiError) {
        setFormError("Cannot load user profile.")
        return
      }
      userService.user = user
      navigate(pathList.list.reverse())
    } catch (error) {
      setFormError("An unexpected error occurred.")
    }
  }
  return { formError, handleLoginClick }
}

const AdminLogin = () => {
  const { formError, handleLoginClick } = useLogin()

  function showErrors() {
    if (!formError) {
      return <></>
    }
    return (
      <Container>
        <Row>
          <Alert variant="danger">{formError}</Alert>
        </Row>
      </Container>
    )
  }

  return (
    <ReactForm onSubmit={handleLoginClick}>
      <div className="pt-2">
        <Input
          type="email"
          name="email"
          placeholder="Enter Your Email Address"
          label="Email Address"
          isRequired={true}
        />
      </div>
      <div className="pt-1">
        <Input
          type="password"
          name="password"
          placeholder="*******"
          label="Password"
          isRequired={true}
        />
      </div>
      {showErrors()}

      <div className="pt-2">
        <Button
          className="w-100 d-flex align-items-center dark-bg"
          buttonType={"submit"}
          name={"admin_login"}
          centerLabel={true}
          label={"Login"}
        />
      </div>
    </ReactForm>
  )
}

export default AdminLogin
