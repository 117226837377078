import { Navigate, Outlet } from "react-router-dom"
import { useUser } from "../hooks"
import { pathList } from "../routes/pathList"

export default function AuthRequired() {
  const user = useUser()

  if (user) {
    return <Outlet />
  }

  return <Navigate to={pathList.login.reverse()} />
}
