import { generatePath } from "react-router-dom"

interface PathParams {
  [key: string]: number | string
}

function makeRelativeUrl(path: string, params?: PathParams) {
  if (!params) {
    return path
  }
  return generatePath(path, params)
}

export const path = (pathString: string) => {
  return {
    path: pathString,
    reverse: (params?: PathParams, absolute = false): string => {
      let url = pathString
      if (!url.startsWith("/")) {
        url = "/" + url
      }

      let relativeUrl = makeRelativeUrl(url, params)
      if (!relativeUrl.endsWith("/")) {
        relativeUrl += "/"
      }
      if (absolute) {
        return new URL(relativeUrl, window.location.origin).toString()
      }
      return relativeUrl
    },
  }
}
