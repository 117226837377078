import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Form, Alert } from "react-bootstrap"
import logoSvg from "../../assets/imgs/logo.svg"
import artWork from "../../assets/Artwork.png"
import "./patientLogin.module.scss"
import Input from "../../components/Input/input"
import Button from "../../components/Button"
import { useNavigate } from "react-router-dom"
import s from "../patientLogin/patientLogin.module.scss"
import Slider from "../../components/Slider/slider"
import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import shareService from "../../services/ShareService"
import { ApiError, handleApiError } from "../../services/ApiService"
import Lookup from "../lookup"
import { pathList } from "../../routes/pathList"
import { slides } from "../landing/slides"
import ShareLink from "../../components/ShareLink"
import { NavigateFunction } from "react-router-dom"
import {
  convertDateFormat,
  getParsedDate,
  validateDateInput,
  isInTheFuture,
} from "../../utils"

function NotFound({
  notFound,
  errorMessage,
}: {
  notFound?: boolean
  errorMessage?: string
}) {
  if (!notFound) {
    return <></>
  }
  return (
    <Container>
      <Row>
        <Alert variant="danger">{errorMessage}</Alert>
      </Row>
    </Container>
  )
}

export const patientDetail = async (
  formData: HTMLFormElement,
  navigate: NavigateFunction,
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  try {
    const share = await shareService.patientLookup(
      formData.code.value,
      convertDateFormat(formData.dob.value as string)
    )

    if (share instanceof ApiError) {
      if (share.notFound) {
        setNotFound(true)
        setErrorMessage(share.errorMessage)
      }
      return handleApiError(share)
    }

    const studies = await shareService.studies(formData.code.value)
    if (studies instanceof ApiError) {
      return handleApiError(studies)
    }

    navigate(pathList.lookupWithCode.reverse({ code: formData.code.value }), {
      state: { share, studies },
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorResp = error?.response?.data
    setErrorMessage(
      errorResp.length !== 0 ? errorResp[0] : "An unexpected error occurred."
    )
  }
}

const PatientLogin = () => {
  const [code] = useState(localStorage.getItem("code"))
  const [notFound, setNotFound] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>("")
  const [dobError, setDobError] = useState<string>("")
  const [requestStatus, setRequestStatus] = useState<string>("pending")

  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem("code", "")
  }, [])

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setRequestStatus("inProgress")
      if (
        ((e.nativeEvent as SubmitEvent).submitter as HTMLInputElement)?.name ===
        "patient_login"
      ) {
        const form = e.currentTarget
        e.preventDefault()
        await patientDetail(form, navigate, setNotFound, setErrorMessage)
        setRequestStatus("completed")
      }
    },
    [navigate]
  )
  const onDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget
    const parsedInput = getParsedDate(input.value)
    const isValid = validateDateInput(parsedInput)
    let errorMessage = ""

    if (!isValid) {
      const formats = getParsedDate.formats.join(", ")
      errorMessage = `Invalid date format. Accepted formats: ${formats}`
    } else if (isInTheFuture(parsedInput.toDate())) {
      errorMessage = "Date of birth can not be in the future."
    }

    setDobError(errorMessage)
    input.setCustomValidity(errorMessage)
  }

  return (
    <Container
      fluid
      className={`position-relative ${s.patientLoginBackground}`}
    >
      <Row className="d-flex flex-column-mbl min-vh-100 justify-content-center">
        <Col
          md={5}
          xl={4}
          sm={12}
          className="z-1 d-flex align-items-center  justify-content-center"
        >
          <Card className={`m-md-5 m-sm-3 ${s.cardContainer}`}>
            <Card.Body className="login-wrapper p-4 pt-5 pd-5">
              <div className="logo d-inline-block">
                <img src={logoSvg} alt="logo" />
              </div>
              <p className="grey-color logo-cap mt-2">
                Information Management System
              </p>
              <div className="second-interface">
                <Form
                  method="post"
                  onSubmit={onSubmit}
                  rel="noreferrer"
                  className="pt-2"
                >
                  <div className="mb-2">
                    <Input
                      type="text"
                      name="code"
                      placeholder="Enter Code"
                      label="Code"
                      isRequired={true}
                      defaultValue={code || ""}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      type="text"
                      name="dob"
                      placeholder="MM/DD/YYYY"
                      label="Patient date of birth"
                      className="date-input"
                      isRequired={true}
                      onChange={onDobChange}
                      aria-invalid={!!dobError}
                      aria-describedby="dobError"
                    />
                  </div>
                  <NotFound notFound={notFound} errorMessage={errorMessage} />
                  <Button
                    className="w-100 d-flex align-items-center dark-bg"
                    label={`${
                      requestStatus === "completed" ||
                      requestStatus === "pending"
                        ? "Access My Images"
                        : ""
                    }`}
                    buttonType={"submit"}
                    name={"patient_login"}
                    centerLabel={true}
                    showSpinner={requestStatus === "inProgress"}
                  />
                </Form>
                <div className="mt-1 mb-1 text-center">
                  <div className="d-flex align-center justify-content-between mt-3">
                    <p className="grey-color mb-0">Having trouble?</p>
                    <p className="mb-0">
                      <a
                        href="mailto:support@sarcmediq.com"
                        className="cursor-pointer"
                      >
                        Contact Us
                      </a>
                    </p>
                  </div>
                  {code?.length !== 0 ? (
                    <hr />
                  ) : (
                    <img className="w-75 mt-2" src={artWork} alt="artwo rk" />
                  )}
                </div>
                {code?.length !== 0 && (
                  <ShareLink link={`${window.location.href}${code}`} />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col
          md={7}
          xl={8}
          sm={0}
          className={`align-content-center position-relative overflow-hidden ${s.backgroundImage} d-none d-md-block`}
        >
          <div className="login-wrapper">
            <Slider slides={slides} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

Lookup.loader = async ({ params }: LoaderFunctionArgs) => {
  return { code: params.code }
}

export default PatientLogin
