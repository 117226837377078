import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import s from "./ToolbarButton.module.scss"

export const HelpButton = () => {
  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip" bsPrefix={s.helpTool}>
          <b>You can use the following tools on MRI/CT</b>
          <ul>
            <li>For Different slices: Scroll</li>
            <li>For Brightness/Contrast: Click + Hold left mouse </li>
            <li>For Zoom: Ctrl + Left Click</li>
          </ul>
        </Tooltip>
      }
    >
      <Button variant="secondary" size="sm">
        <FontAwesomeIcon icon="question" />
      </Button>
    </OverlayTrigger>
  )
}
