import React from "react"
import { Table } from "react-bootstrap"
import { ShareFile } from "../../services/ShareService/types"
import { formatFileSize } from "../../utils"

interface ShareFileListProps {
  files: ShareFile[]
}

export function ShareFileList({ files }: ShareFileListProps) {
  return (
    <Table bordered={true} striped={true} hover={true}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        {files.map((f) => (
          <tr key={f.pk}>
            <td>{f.originalName}</td>
            <td>{formatFileSize(f.size)}</td>
            <td>
              <a href={f.link} target="_blank" rel="noreferrer">
                Download
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
