import { Draft } from "immer"
import { Displayable, ShareFile } from "../../../services/ShareService/types"
import { Instance } from "../types"
import { ActionPayload } from "./reducers"
import {
  PanelLayout,
  PanelState,
  PlaybackControl,
  PlaybackState,
  State,
} from "./types"

export interface ReducerActions {
  [name: string]: (state: Draft<State>, action: ActionPayload) => void
}

interface SetInstancePayload {
  object: Displayable
  index?: number
}

export interface SetLoadedPayload {
  index: number
  loaded: boolean
}

export enum Actions {
  setObject = "setObject",
  setInstances = "setInstances",
  setLoaded = "setLoaded",
  setInstance = "setInstance",
  setPlaybackStateAll = "setPlaybackStateAll",
  setPlaybackState = "setPlaybackState",
  setPanelLayout = "setPanelLayout",
  setSelectedPanel = "setSelectedPanel",
  setBadInstancesCount = "setBadInstancesCount",
  setFiles = "setFiles",
}

const actions: ReducerActions = {
  [Actions.setObject](state, action: ActionPayload<SetInstancePayload>) {
    const singlePanel = state.layout.rows * state.layout.cols === 1
    const selectedPanel = state.panels.findIndex(
      (panelState) => panelState.isSelected
    )
    const noSelection = selectedPanel === -1
    let panelIndex: number = selectedPanel
    if (action.payload.index !== undefined) {
      panelIndex = action.payload.index
    } else if (singlePanel) {
      panelIndex = 0
    } else if (noSelection) {
      panelIndex = state.panels.findIndex(
        (panelState) => panelState.object == null
      )
    }
    if (panelIndex > -1) {
      state.panels[panelIndex].object = action.payload.object
    }
  },
  [Actions.setInstances](state, action: ActionPayload<Instance[]>) {
    state.instances = action.payload
  },
  [Actions.setFiles](state, action: ActionPayload<ShareFile[]>) {
    state.files = action.payload
  },
  [Actions.setBadInstancesCount](state, action: ActionPayload<string>) {
    state.badInstancesCount = state.badInstancesCount + 1
    const instances: Array<Instance> = state.instances.filter(
      (obj) => obj.instanceId !== action.payload
    )
    state.instances = instances
  },
  [Actions.setLoaded](state, action: ActionPayload<SetLoadedPayload>) {
    state.panels[action.payload.index].isLoaded = action.payload.loaded
  },
  [Actions.setPlaybackStateAll](state, action: ActionPayload<PlaybackState>) {
    let nextState = action.payload
    if (nextState === "next" || nextState === "prev") {
      nextState += "-" + Math.random()
    }
    state.panels.forEach(
      (panelState) =>
        (panelState.playbackInfo.state = nextState as PlaybackState)
    )
  },
  [Actions.setPlaybackState](state, action: ActionPayload<PlaybackControl>) {
    let nextState = action.payload.state
    if (nextState === "next" || nextState === "prev") {
      nextState += "-" + Math.random()
    }
    const selectedPanel =
      action.payload.index === -1
        ? state.panels.length === 1
          ? 0
          : state.panels.findIndex(
              (panelState: PanelState) => panelState.isSelected
            )
        : action.payload.index
    // the Math.random is a stupid hack to get the state to update every time
    // next/prev is pushed.  open to better ideas.
    if (selectedPanel !== undefined && selectedPanel > -1) {
      state.panels[selectedPanel].playbackInfo.state =
        nextState as PlaybackState
    }
  },
  [Actions.setPanelLayout](state, action: ActionPayload<PanelLayout>) {
    state.layout = action.payload
    const numElements = state.layout.rows * state.layout.cols
    if (numElements > state.panels.length) {
      /* pad if needed; find last visible instance and append as many as possible  */
      let lastSelected = 0
      const visibleInstances = state.panels
        .map((ps) => ps.object?.pk)
        .filter((pk) => !!pk) as Displayable["pk"][]
      const objects = [...state.instances, ...state.files]
      for (let i = objects.length - 1; i > 0; i--) {
        if (visibleInstances.includes(objects[i].pk)) {
          lastSelected = i
          break
        }
      }

      const numPad = numElements - state.panels.length
      const defaultArray = [...Array(numPad)].map((v, idx) =>
        defaultPanelState(objects[lastSelected + 1 + idx])
      )
      state.panels = state.panels.concat(defaultArray)
    } else {
      /* otherwise slice it */
      state.panels = state.panels.slice(0, numElements)
    }
    if (numElements === 1) {
      state.panels[0].isSelected = true
    } else {
      state.panels.forEach((panelState) => (panelState.isSelected = false))
    }
  },
  [Actions.setSelectedPanel](state, action: ActionPayload<number>) {
    state.panels.forEach((panelState, idx) => {
      panelState.isSelected = idx === action.payload
    })
  },
}

const defaultPanelState = (object?: Displayable): PanelState => ({
  object,
  isSelected: false,
  isLoaded: false,
  playbackInfo: { fps: -1, state: "first" },
})

export default actions
