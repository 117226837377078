import c from "classnames"
import React from "react"
import Modal from "react-bootstrap/Modal"
import s from "./MainToolbar.module.scss"
import { useDispatch } from "./context"
import { useUser } from "../../hooks"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { pathList } from "../../routes/pathList"
import { useNavigate } from "react-router-dom"

interface ModalToolbarProps {
  hidden: boolean
  ohifViewer: boolean
}

const ModalToolbar = ({ hidden, ohifViewer }: ModalToolbarProps) => {
  if (hidden) {
    return <></>
  }
  return (
    <div className={`col-md-${ohifViewer ? "12" : "5"} text-end pl-0`}>
      <Modal.Header closeButton />
    </div>
  )
}

interface MainToolbarProps {
  isDefaultToolbar: boolean
  badInstancesCount: number
  ohifViewer: boolean
  hasUser: boolean
}

const BadErrorMessage = () => {
  const { state } = useDispatch()
  const user = useUser()

  return user && state.badInstancesCount > 0 ? (
    <div className={"col-md-2"} style={{ textAlign: "center", color: "red" }}>
      <p>{state.badInstancesCount} images could not be displayed</p>
    </div>
  ) : null
}

export const MainToolbar = ({
  ohifViewer,
  isDefaultToolbar,
  badInstancesCount,
  hasUser,
}: MainToolbarProps) => {
  const { state } = useDispatch()
  const navigate = useNavigate()

  let colSize = 5
  if (state.badInstancesCount > 0 && !isDefaultToolbar) {
    colSize = 4
  }
  if (isDefaultToolbar && !state.badInstancesCount) {
    colSize = 6
  }
  const colClass = `col-md-${colSize}`

  const pressEscape = () => {
    if (hasUser) {
      navigate(pathList.list.reverse())
    } else {
      const escapeEvent = new KeyboardEvent("keydown", {
        key: "Escape",
        keyCode: 27,
        code: "Escape",
      })
      document.dispatchEvent(escapeEvent)
    }
  }

  return (
    <div className={c(s.toolbar, "container-fluid")}>
      <div className="row mb-2 mt-2">
        {!ohifViewer ? (
          <>
            {" "}
            <div className={c(colClass, "text-start pr-0")}>
              <Button variant="link" onClick={pressEscape} size="sm">
                <FontAwesomeIcon icon="arrow-left" /> Back to Home
              </Button>
            </div>
            <BadErrorMessage />
          </>
        ) : (
          <ModalToolbar hidden={isDefaultToolbar} ohifViewer={ohifViewer} />
        )}
      </div>
    </div>
  )
}
