import React from "react"
import { FormControl, FormGroup, FormLabel } from "react-bootstrap"
import { Share } from "../services/ShareService/types"
import dayjs from "dayjs"
import { getParsedDate, isInTheFuture, validateDateInput } from "../utils"

export type DOBFieldProps = {
  label?: string
  defaultValue?: Share["dob"]
  inputRef?: React.RefObject<HTMLInputElement>
  formValid?: (isValid: boolean) => void
  fieldName?: string
}

const DOBField = ({
  defaultValue,
  label,
  inputRef,
  formValid,
  fieldName = "dob",
}: DOBFieldProps) => {
  function onDobChange(e: React.ChangeEvent<HTMLInputElement>) {
    // needs refactoring to validate more fields. And/or a lib for form validation
    const input = e.currentTarget
    const parsedInput = getParsedDate(input.value)
    const isValid = validateDateInput(parsedInput)
    if (!isValid) {
      const formats = getParsedDate.formats.join(", ")
      input.setCustomValidity(
        `Invalid date format. Accepted formats: ${formats}`
      )
    } else if (isInTheFuture(parsedInput.toDate())) {
      input.setCustomValidity("Date of birth can not be in the future.")
    } else {
      input.setCustomValidity("")
    }
    formValid?.(isValid)
  }

  return (
    <FormGroup className="mb-3" controlId="formDob">
      <FormLabel>{label}</FormLabel>
      <FormControl
        type="text"
        placeholder="Date of Birth"
        name={fieldName}
        defaultValue={
          defaultValue &&
          dayjs(defaultValue).format(getParsedDate.defaultFormat)
        } // if not null then put the defaultValue otherwise dayjs gives the current date
        ref={inputRef}
        onChange={onDobChange}
        required
      />
    </FormGroup>
  )
}

export default DOBField
