import React, { forwardRef } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { getDicomImageUrl } from "./patientFiles"
import s from "./patientFiles.module.scss"
import c from "classnames"
import pdfIcon from "./assets/icon/pdf-icon.png"
import downloadIcon from "./assets/imgs/downloadIcon.png"
import eyeIcon from "./assets/imgs/eyeIcon.png"
import { Instance, Study } from "../DicomViewer/types"
import { ShareFile } from "../../services/ShareService/types"
import { formatDate } from "./utils"
import { useMediaQuery } from "react-responsive"

interface CollectionViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRows: any[]
  isFileDownloading: boolean
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleEyeIconClickForStudy: (studyId: Study["studyId"]) => void
  handleEyeIconClickForFile: (file: ShareFile) => void
  handleStudyDownloadClick: (studyId: Study["studyId"]) => void
  handleFileDownloadClick: (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"]
  ) => void
}

export const CollectionView = forwardRef<HTMLDivElement, CollectionViewProps>(
  (
    {
      currentRows,
      handleImageError,
      handleEyeIconClickForStudy,
      handleEyeIconClickForFile,
      handleStudyDownloadClick,
      handleFileDownloadClick,
      isFileDownloading,
    },
    ref
  ) => {
    const isXSmallScreen = useMediaQuery({ query: "(max-width: 575px)" })

    const [downloadingIndex, setDownloadingIndex] = React.useState(0)

    const handleStudyDownload = (
      studyId: Instance["studyId"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleStudyDownloadClick(studyId)
    }

    const handleFileDownload = (
      link: ShareFile["link"],
      originalName: ShareFile["originalName"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleFileDownloadClick(link, originalName)
    }

    const renderColumn = (count: number) => (
      <Col xs={12} sm={4}>
        <h6 className="m-0">
          <strong># Files</strong>
          <span> {count}</span>
        </h6>
      </Col>
    )

    const Tag = isXSmallScreen ? "span" : "p"

    return (
      <div ref={ref}>
        {currentRows.map((item, index) => (
          <Card
            className={c(s.collectionContainer, "p-3", "w-100", "mb-4")}
            key={item.studyId || item.id}
          >
            {item.studyId ? (
              <>
                <Row>
                  <Col xs={12} sm={4}>
                    <img
                      className={`${isXSmallScreen ? "mb-2" : ""} ${
                        s.thumbnail
                      }`}
                      src={getDicomImageUrl(item.instances[0])}
                      onError={handleImageError}
                      alt="File Thumbnail"
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6 className="mr-xs-2">
                      <strong>Study Date</strong>
                    </h6>
                    <Tag>
                      {" "}
                      {item.date ? formatDate(item.date) : "Not Available"}
                    </Tag>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Body Part</strong>
                    </h6>
                    <Tag>{item.bodyPart || "Not Available"}</Tag>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Modality</strong>
                    </h6>
                    <Tag>{item.instances[0].modality || "N/A"}</Tag>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Description</strong>
                    </h6>
                    <Tag>{item.studyDescription || "Not Available"}</Tag>
                  </Col>
                  {isXSmallScreen && renderColumn(item.count)}
                </Row>
                {isXSmallScreen && <hr />}
                <Row className="align-items-center">
                  {!isXSmallScreen && renderColumn(item.count)}
                  <Col>
                    <img
                      className="w-auto m-2"
                      src={eyeIcon}
                      alt="eye Icon"
                      onClick={() => handleEyeIconClickForStudy(item.studyId)}
                    />
                    <span>View</span>
                  </Col>
                  <Col>
                    {downloadingIndex === index && isFileDownloading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <img
                          className="w-auto m-2"
                          src={downloadIcon}
                          alt="Download Icon"
                          onClick={() =>
                            handleStudyDownload(item.studyId, index)
                          }
                        />
                        <span>Download</span>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={12} sm={4}>
                    <img
                      src={
                        item.contentType?.split("/")[1].toLowerCase() === "pdf"
                          ? pdfIcon
                          : item.link
                      }
                      alt="File Thumbnail"
                      className={`${isXSmallScreen ? "mb-2" : ""} ${
                        s.thumbnail
                      }`}
                      onError={handleImageError}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Study Date</strong>
                    </h6>
                    <Tag>Not Available</Tag>
                  </Col>

                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Body Part</strong>
                    </h6>
                    <Tag>Not Available</Tag>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Modality</strong>
                    </h6>
                    <Tag>Not Available</Tag>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    className={`${isXSmallScreen ? "d-flex gap-1" : ""}`}
                  >
                    <h6>
                      <strong>Description</strong>
                    </h6>
                    <Tag>Not Available</Tag>
                  </Col>
                  {isXSmallScreen && renderColumn(1)}
                </Row>
                {isXSmallScreen && <hr />}
                <Row>
                  {!isXSmallScreen && renderColumn(1)}
                  <Col>
                    <img
                      className="w-auto m-2"
                      src={eyeIcon}
                      alt="eye Icon"
                      onClick={() => handleEyeIconClickForFile(item)}
                    />
                    <span>View</span>
                  </Col>
                  <Col>
                    {downloadingIndex === index && isFileDownloading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <img
                          className="w-auto m-2"
                          src={downloadIcon}
                          alt="Download Icon"
                          onClick={() =>
                            handleFileDownload(
                              item.link,
                              item.originalName,
                              index
                            )
                          }
                        />
                        <span>Download</span>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Card>
        ))}
      </div>
    )
  }
)
