import { useLocation } from "react-router-dom"
import { pathList, PathListKeys } from "../routes/pathList"
import userService from "../services/UserService"

export function useUser() {
  return userService.user
}

export function useMatchingPath(paths: PathListKeys[]): boolean {
  const location = useLocation()

  return (
    paths.find((pathKey) => {
      const rePath = pathList[pathKey].path.replace(/:[^/]+/g, "[^/]+")
      return location.pathname.match(rePath) !== null
    }) !== undefined
  )
}
