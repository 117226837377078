import banner1 from "./imgs/bannerImage1.png"
import banner2 from "./imgs/bannerImage2.png"
import banner3 from "./imgs/bannerImage3.png"

export const slides = [
  {
    img: banner1,
    heading: "Simple Secure Anytime Anywhere.",
    text: "Cloud Based Enterprise PACS & Patient Data Solution",
  },
  {
    img: banner2,
    heading: "AI Driven Cloud-Based Workflow Solution",
    text: "Cloud Based Enterprise PACS & Patient Data Solution",
  },
  {
    img: banner3,
    heading: "Bringing Affordable Healthcare Solutions to the World",
    text: "Cloud Based Enterprise PACS & Patient Data Solution",
  },
]
