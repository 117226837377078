import { useCallback, useEffect } from "react"
import { Key } from "ts-key-enum"

export function useKeyboardHandler(key: Key | string, handler: () => void) {
  const myHandler = useCallback(
    (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement).tagName.toUpperCase()
      if (tagName !== "INPUT" && tagName !== "TEXTAREA") {
        if (ev.key === key) {
          handler()
          ev.preventDefault()
        }
      }
    },
    [key, handler]
  )

  useEffect(() => {
    window.addEventListener("keyup", myHandler, true)
    return () => window.removeEventListener("keyup", myHandler, true)
  })
}
