import React, { useReducer } from "react"
import { DicomImageStack, ImageStackFactory, Instance } from "../types"
import { ShareFile } from "../../../services/ShareService/types"
import { ActionPayload, mainReducer } from "./reducers"
import { State } from "./types"

interface ViewProviderProps extends React.PropsWithChildren {
  instances: Instance[]
  files: ShareFile[]
  getImageStack: ImageStackFactory
  badInstancesCount: number
}

type ViewDispatch = React.Dispatch<ActionPayload>

interface ViewContentType {
  state: State
  getImageStack: ImageStackFactory
  dispatch: ViewDispatch
}

const initialState: State = {
  layout: { rows: 1, cols: 1 },
  panels: [
    {
      object: undefined,
      isSelected: true,
      isLoaded: false,
      playbackInfo: { fps: -1, state: "first" },
    },
  ],
  instances: [],
  files: [],
  badInstancesCount: 0,
}

export const ViewContext = React.createContext<ViewContentType>({
  state: initialState,
  getImageStack: () => ({} as DicomImageStack),
  dispatch: (payload) => {
    return
  },
})

export const ViewProvider = ({
  instances,
  files,
  getImageStack,
  badInstancesCount,
  children,
}: ViewProviderProps) => {
  const [state, dispatch] = useReducer(mainReducer, {
    ...initialState,
    instances,
    files,
    badInstancesCount,
  })

  return (
    <ViewContext.Provider value={{ state, getImageStack, dispatch }}>
      {children}
    </ViewContext.Provider>
  )
}
