import React from "react"
import Button from "../Button"

interface props {
  link: string
}

const ShareLink = ({ link }: props) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard.")
    })
  }

  return (
    <div className="share-link-section mb-5">
      <Button
        variant="link"
        label="Click Here to Copy Imaging Link to Clipboard"
        className="p-0 mt-2 mb-2"
        onClick={handleCopyLink}
      />
      <p>You can paste the link into a text or email for sharing with others</p>
    </div>
  )
}

export default ShareLink
